import { ref, watch, computed } from '@vue/composition-api';
import axios from '@axios';
import useAuth from '@/auth/useAuth';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
const { i18n } = useI18nUtils();
function i18nT(str){
    return i18n(str)
  };
export default function useDocumentList(isTemplate) {
  const refDocumentListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: 'checkbox', label: '', thClass: 'tbl-chk', tdClass: 'tbl-chk' },
    { key: 'name', label: i18nT(`Name`), sortable: true, thClass: 'pl-1', tdClass: 'pl-1' },
    { key: 'file', label: i18nT(`File`), sortable: true },
    { key: 'assigned_to', label: i18nT(`Assigned to`), sortable: true, thClass: "sk-header-content" },
    { key: 'created_by', label: i18nT(`Created by`), sortable: true, thClass: "sk-header-content" },
    { key: 'size', label: i18nT(`File Size`), sortable: true, thClass: "sk-header-content" },
    { key: 'created', label: i18nT(`Created`), sortable: true },
    {
      key: 'action',
      label: i18nT(`Actions`),
      thClass: 'tbl-actions',
      tdClass: 'sticky-column',
      stickyColumn: true
    },
  ];
  const perPage = ref(10);
  const totalDocuments = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref('');
  const sortBy = ref('id');
  const isSortDirDesc = ref(true);
  const statusFilter = ref(null);
  const documentType = ref({value: -1, text: i18nT('All types')});
  const documentGroup = ref({value: -1, text: i18nT('All groups')});
  const assignedTo = ref({value: 0, text: i18nT('Any user')});
  const status = ref({value: -1, text: i18nT('All')});
  const software = ref({value: -1, text: i18nT('All')});
  const documents = ref([]);
  const documentFilter = ref({value: "", text: i18nT(`All`)});

  const dataMeta = computed(() => {
    const localItemsCount = refDocumentListTable.value
      ? refDocumentListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalDocuments.value,
    };
  });

  const refetchData = () => {
    refDocumentListTable.value.refresh();
  };

  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      statusFilter,
      documentType,
      documentGroup,
      documentFilter,
      assignedTo,
      status,
      software,
      isSortDirDesc,
      sortBy,
    ],
    () => {
      refetchData();
    }
  );

  const fetchDocuments = (ctx, callback) => {
    const params = {
      page: currentPage.value,
      show_per_page: perPage.value,
      sort_by: sortBy.value,
      sort_order: isSortDirDesc.value ? 'DESC' : 'ASC',
      search: searchQuery.value
    };

    if (isTemplate) {
      params.templates = 1;
    }

    if (documentType.value.value != -1) {
      params['filters[type]'] = documentType.value.value;
    }
    if (documentGroup.value.value != -1) {
      params['filters[group]'] = documentGroup.value.value;
    }
    if (assignedTo.value.value != '') {
      params['filters[assigned_to]'] = assignedTo.value.value;
    }
    if (software.value.value != -1) {
      params['filters[software]'] = software.value.value;
    }
    if (documentFilter.value.value != '') {
      params['filters[created_shared]'] = documentFilter.value.value;
    }

    axios
      .get(`documents`, {
        params,
      })
      .then(({ data }) => {
        documents.value = data.data.documents;
        callback(data.data.documents);
        totalDocuments.value = data.data.pagination.records_count;
      });
  };

  return {
    fetchDocuments,
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refDocumentListTable,
    statusFilter,
    totalDocuments,
    documentType,
    documentGroup,
    assignedTo,
    status,
    software,
    documents,
    documentFilter,
    refetchData,
  };
}
