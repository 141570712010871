<template>
    <div>
        <b-card no-body>
            <div class="m-2">
                <div>
                    <b-row>
                        <b-col cols="12">
                            <h1 class="float-left">{{ i18nT(`Document templates`) }}</h1>
                            <b-button
                                variant="primary"
                                :to="{ name: 'create-document-template'}"
                                class="float-right"
                                v-if="iCan('documents', 'write')"
                            >
                                <feather-icon icon="PlusCircleIcon"/>
                                {{ i18nT(`Add new`) }}
                            </b-button>
                        </b-col>
                    </b-row>
                    <div class="mb-1">
                        <span class="text-muted">{{ i18nT(`Showing`) }} {{
                                dataMeta.from
                            }} {{ i18nT(`to`) }} {{ dataMeta.to }} {{ i18nT(`of`) }} {{
                                dataMeta.of
                            }} {{ i18nT(`entries`) }}</span>
                    </div>
                    <b-row>
                        <b-col
                            cols="12"
                            md="3"
                            v-if="controlVisible"
                        >
                            <b-form-group
                                :label="i18nT(`Document type`)"
                                label-for="documentType"
                            >
                                <v-select
                                    id="documentType"
                                    :clearable="false"
                                    v-model="documentType"
                                    :options="documentTypes"
                                    label="text"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col
                            cols="12"
                            md="3"
                            v-if="displayExtentControls && controlVisible"
                        >
                            <b-form-group
                                :label="i18nT(`Document group`)"
                                label-for="documentGroup"
                            >
                                <v-select
                                    id="documentGroup"
                                    :clearable="false"
                                    v-model="documentGroup"
                                    :options="documentGroups"
                                    label="text"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col
                            cols="12"
                            md="3"
                            v-if="displayExtentControls && controlVisible"
                        >
                            <b-form-group
                                :label="i18nT(`Assigned to`)"
                                label-for="assignee"
                            >
                                <v-select
                                    id="assignee"
                                    :clearable="false"
                                    v-model="assignedTo"
                                    :options="assigneeFilter"
                                    label="text"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col
                            cols="12"
                            md="3"
                            v-if="displayExtentControls && controlVisible"
                        >
                            <b-form-group
                                :label="i18nT(`Software module`)"
                                label-for="software"
                            >
                                <v-select
                                    id="software"
                                    :clearable="false"
                                    v-model="software"
                                    :options="softwareFilter"
                                    label="text"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col
                            cols="12"
                            md="3"
                            v-if="!displayExtentControls"
                        >
                            <b-form-group
                                :label="i18nT(`Documents`)"
                                label-for="Documents"
                            >
                                <v-select
                                    id="Documents"
                                    :clearable="false"
                                    v-model="documentFilter"
                                    :options="documentFilters"
                                    label="text"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
            </div>

            <div>
                <div>
                    <div
                        class="row mx-2"
                        v-if="displayExtentControls"
                    >
                        <div class="col-12 col-md-6 col-sm 6 mb-1 pl-0">
                            <v-select
                                v-model="perPage"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="perPageOptions"
                                :clearable="false"
                                class="per-page-selector d-inline-block"
                            />
                            <b-dropdown
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                text="Actions"
                                size="md"
                                class="d-inline ml-1"
                                variant="outline-secondary"
                                v-if="selectedItems.length > 0 && iCan('documents', 'write')"
                            >
                                <b-dropdown-item @click="onBulkCopy(data)">
                                    <feather-icon icon="CopyIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Copy`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="onBulkDelete(data)">
                                    <feather-icon
                                        icon="TrashIcon"
                                        color="red"
                                    />
                                    <span
                                        class="align-middle ml-50"
                                        style="color: red"
                                    >{{ i18nT(`Delete`) }}</span>
                                </b-dropdown-item>
                            </b-dropdown>
                            <!-- <b-dropdown
                              size="md"
                              class="d-inline ml-1"
                              variant="outline-secondary"
                            >
                              <template v-slot:button-content>
                                <feather-icon icon="ExternalLinkIcon" />&nbsp;&nbsp;{{i18nT(`Export`)}}
                              </template>
                              <b-dropdown-item @click="onExportExcel()">
                                <feather-icon icon="CopyIcon" />
                                <span class="align-middle ml-50">{{i18nT(`Excel`)}}</span>
                              </b-dropdown-item>
                              <b-dropdown-item @click="onExportCsv()">
                                <feather-icon icon="CopyIcon" />
                                <span class="align-middle ml-50">{{i18nT(`CSV`)}}</span>
                              </b-dropdown-item>
                            </b-dropdown> -->
                        </div>
                        <!-- search input -->
                        <div class="col-12 col-md-6 col-sm 6 mb-1 pr-0">
                            <div class="custom-search d-flex justify-content-end">
                                <b-form-group>
                                    <div
                                        role="group"
                                        class="input-group input-group-merge"
                                    >
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <feather-icon icon="SearchIcon"/>
                                            </div>
                                        </div>
                                        <b-form-input
                                            v-model.lazy="searchQuery"
                                            :placeholder="i18nT(`Search`)"
                                            type="text"
                                            class="d-inline-block"
                                        />
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <b-table
                        ref="refDocumentListTable"
                        :items="fetchDocuments"
                        responsive
                        :fields="computedColumns"
                        primary-key="id"
                        :sort-by.sync="sortBy"
                        show-empty
                        :empty-html="emptyHtml"
                        :sort-desc.sync="isSortDirDesc"
                        class="position-relative"
                        @refreshed="onTableRefresh"
                        v-model="currentItems"
                    >

                        <template v-slot:head(checkbox)="data" v-if="iCan('documents', 'write')">
                            <span>{{ data.label.toUpperCase() }}</span>
                            <b-form-checkbox
                                class="d-inline-block pl-0"
                                @change="onSelectAll"
                                v-model="selectAllStatus"
                            >
                            </b-form-checkbox>
                        </template>

                        <template #head(action)="data">
                            <span>{{ data.label.toUpperCase() }}</span>
                            <div class="sk-column-control">
                                <b-dropdown
                                    no-caret
                                    variant="button"
                                    toggle-class="p-0"
                                    :right="true"
                                    class="pr-1 pl-1 d-flex sk-column-filter"
                                    v-if="betaFeature"
                                    v-b-tooltip = "i18nT(`Customize columns`)"
                                >
                                    <template #button-content>
                                        <feather-icon
                                            icon="SettingsIcon"
                                            size="18"
                                            class="text-right"
                                        />
                                    </template>

                                    <div class="pl-1 pr-1" v-for="(field, index) in avalableFields" :key="'Fields - ' + index">
                                        <b-form-checkbox class="mb-1 mt-1 text-nowrap" v-model="field.visible">{{ i18nT(field.label) }}</b-form-checkbox>
                                    </div>
                                </b-dropdown>
                            </div>
                        </template>

                        <template #cell(checkbox)="data" v-if="iCan('documents', 'write')">
                            <b-form-checkbox
                                v-model="selectedItems"
                                :value="data.item.Id"
                            />
                        </template>

                        <template #cell(name)="data">
                            <div class="media">
                                <div
                                    class="media-aside align-self-center"
                                    @click="navToDocEdit(data.item)"
                                >
                                    <div
                                        class="file-icon"
                                        data-type="doc"
                                    ></div>
                                </div>
                                <div class="media-body">
                                    <b-link
                                        v-if="iCan('documents', 'write')"
                                        class="font-weight-bold d-block text-nowrap"
                                        target="_self"
                                        :to="{ name: 'edit-document', params: { id: data.item.Id } }"
                                    >{{ data.item.Label }}
                                    </b-link>
                                    <span
                                        v-else
                                        class="font-weight-bold d-block text-nowrap"
                                        target="_self"
                                        @click="navToDocEdit(data.item)"
                                    >{{ data.item.Label }}
                                    </span>
                                    <p class="mb-0">{{ getTypeName(data.item.Type) }}</p>
                                </div>
                            </div>
                        </template>

                        <template #cell(file)="data">
                            <b-link
                                :href="data.item.Url ? data.item.Url : (data.item.AttachedFile ? data.item.AttachedFile.DownloadUrl: '')"
                                class="font-weight-bold"
                                target="_blank"
                                :style="{width: '160px', display: 'block'}"
                            >

                                {{
                                    data.item.Url ? data.item.Url : (data.item.AttachedFile ? data.item.AttachedFile.Label : '')
                                }}
                            </b-link>
                        </template>

                        <template #cell(assigned_to)="data">
                            <b-avatar-group size="32px">

                                <b-avatar
                                    class="pull-up"
                                    size="35"
                                    v-for="item in data.item.AssignedTo"
                                    :key="item.Id"
                                    v-b-tooltip.hover="item.FirstName + ' ' + item.LastName"
                                    :text="(item.FirstName? item.FirstName.charAt(0) : '') + (item.LastName ? item.LastName.charAt(0) : '')"
                                    :src="item.ImageUrl"
                                    variant="light-primary"
                                >
                                </b-avatar>
                            </b-avatar-group>
                        </template>

                        <template #cell(size)="data">
                            <p>
                                {{ data.item.FileSize | fileSize }}
                            </p>
                        </template>


                        <template #cell(created)="data">
                            <p>
                                {{ data.item.CreatedAt | date }}
                            </p>
                        </template>

                        <template #cell(action)="data">
                            <b-dropdown
                                variant="link"
                                toggle-class="p-0"
                                no-caret
                                :right="$store.state.appConfig.isRTL"
                                v-if="iCan('documents', 'write')"
                            >

                                <template #button-content>
                                    <feather-icon
                                        icon="MoreVerticalIcon"
                                        size="16"
                                        class="align-middle text-body"
                                    />
                                </template>
                                <b-dropdown-item :to="{ name: 'edit-document', params: { id: data.item.Id } }">
                                    <feather-icon icon="EditIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Edit`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="onCopy(data)">
                                    <feather-icon icon="CopyIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Copy`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="onDelete(data)">
                                    <feather-icon
                                        icon="TrashIcon"
                                        color="red"
                                    />
                                    <span
                                        class="align-middle ml-50"
                                        style="color: red"
                                    >{{ i18nT(`Delete`) }}</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </template>
                    </b-table>

                </div>
                <!-- Disable grid view -->
                <b-tabs
                    pills
                    align="center"
                    v-if="false"
                >
                    <b-tab active>
                        <template #title>
                            <feather-icon
                                icon="ListIcon"
                                size="24"
                                style="margin: 0;"
                            />
                        </template>

                    </b-tab>

                    <b-tab>
                        <template
                            #title
                            style="text-align: center"
                        >
                            <feather-icon
                                icon="GridIcon"
                                size="24"
                                style="margin: 0;"
                            />
                        </template>
                        <div class="task-container">
                            <b-row class="task-item">
                                <b-col
                                    cols="3"
                                    v-for="document in documents"
                                    :key="document.Id"
                                >
                                    <b-card style="border: 1px solid rgba(0, 0, 0, 0.07);">
                                        <b-card-body class="p-0">
                                            <p> {{ getTypeName(document.Type) }}</p>
                                            <h3 style="height: 50px;">{{ document.Label }}</h3>
                                            <p
                                                style="height: 45px; overflow: hidden;"
                                                v-html="document.Description"
                                            ></p>
                                            <b-list-group
                                                class="list-group-messages"
                                                style="height: 200px"
                                            >
                                                <b-list-group-item
                                                    class="p-0 border-0 line-item"
                                                    v-if="document.CreatedAt"
                                                >
                                                    <feather-icon
                                                        icon="CalendarIcon"
                                                        size="18"
                                                        class="mr-75"
                                                    />
                                                    <span
                                                        class="align-text-bottom line-height-1">Created {{ document.CreatedAt | date }}</span>
                                                </b-list-group-item>
                                                <b-list-group-item
                                                    class="p-0 border-0 line-item"
                                                    v-if="document.UpdatedAt"
                                                >
                                                    <feather-icon
                                                        icon="CheckCircleIcon"
                                                        size="18"
                                                        class="mr-75"
                                                    />
                                                    <span
                                                        class="align-text-bottom line-height-1">Last update {{ document.UpdatedAt | date }}</span>
                                                </b-list-group-item>
                                                <b-list-group-item
                                                    class="p-0 border-0 line-item"
                                                    v-if="document.DocumentDueDate"
                                                >
                                                    <feather-icon
                                                        icon="CastIcon"
                                                        size="18"
                                                        class="mr-75"
                                                    />
                                                    <span class="align-text-bottom line-height-1">Due {{
                                                            document.DocumentDueDate
                                                        }}</span>
                                                </b-list-group-item>
                                                <b-list-group-item
                                                    class="p-0 border-0 line-item"
                                                    v-if="document.DocumentGroups"
                                                >
                                                    <feather-icon
                                                        icon="ListIcon"
                                                        size="18"
                                                        class="mr-75"
                                                    />
                                                    <span class="align-text-bottom line-height-1">
                            <span v-html="document.DocumentGroups"/>
                          </span>
                                                </b-list-group-item>
                                                <div v-if="document.AssignedTo">
                                                    <p class="mt-2">
                                                        {{ i18nT(`Assigned to`) }}
                                                    </p>
                                                    <b-list-group-item class="p-0 border-0">
                                                        <b-avatar
                                                            class="mr-1"
                                                            size="32"
                                                            :text="getAvatarName(getAssigneeName(document.AssignedTo))"
                                                            variant="light-success"
                                                        >
                                                        </b-avatar>
                                                        <span class="align-text-bottom line-height-1">{{
                                                                getAssigneeName(document.AssignedTo)
                                                            }}</span>
                                                    </b-list-group-item>
                                                </div>
                                            </b-list-group>
                                            <div>
                                            </div>

                                        </b-card-body>

                                        <b-card-footer class="pb-0 pt-1 pr-1 pl-1 mt-2 ml-n2 mr-n2 text-top text-right">
                                            <b-dropdown
                                                variant="link"
                                                toggle-class="p-0"
                                                no-caret
                                                v-if="iCan('documents', 'write')"
                                            >

                                                <template #button-content>
                                                    <b-button>
                                                        {{i18nT(`Actions`)}}
                                                    </b-button>
                                                </template>
                                                <b-dropdown-item
                                                    :to="{ name: 'edit-document', params: { id: document.Id } }">
                                                    <feather-icon icon="EditIcon"/>
                                                    <span class="align-middle ml-50">{{ i18nT(`Edit`) }}</span>
                                                </b-dropdown-item>
                                                <b-dropdown-item @click="onCopy({item: document})">
                                                    <feather-icon icon="CopyIcon"/>
                                                    <span class="align-middle ml-50">{{ i18nT(`Copy`) }}</span>
                                                </b-dropdown-item>
                                                <b-dropdown-item @click="onDelete({item: document})">
                                                    <feather-icon
                                                        icon="TrashIcon"
                                                        color="red"
                                                    />
                                                    <span
                                                        class="align-middle ml-50"
                                                        style="color: red"
                                                    >{{ i18nT(`Delete`) }}</span>
                                                </b-dropdown-item>
                                            </b-dropdown>
                                        </b-card-footer>
                                    </b-card>
                                </b-col>
                            </b-row>
                        </div>
                    </b-tab>
                </b-tabs>

                <div class="mx-1 mb-2">
                    <b-row>
                        <!-- <b-col> -->
                        <!-- </b-col> -->
                        <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-start"
                        >
                            <b-form-checkbox
                                class="d-inline-block mx-1"
                                @change="onSelectAll"
                                v-model="selectAllStatus"
                            >
                                {{ i18nT(`All`) }}
                            </b-form-checkbox>
                            <span class="text-muted">{{ i18nT(`Showing`) }} {{
                                    dataMeta.from
                                }} {{ i18nT(`to`) }} {{ dataMeta.to }} {{ i18nT(`of`) }} {{
                                    dataMeta.of
                                }} {{ i18nT(`entries`) }}</span>
                        </b-col>
                        <!-- Pagination -->
                        <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-end"
                        >
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="totalDocuments"
                                :per-page="perPage"
                                first-number
                                last-number
                                class="mb-0 mt-1 mt-sm-0"
                                prev-class="prev-item"
                                next-class="next-item"
                            >
                                <template #prev-text>
                                    <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                    />
                                </template>
                            </b-pagination>

                        </b-col>

                    </b-row>
                </div>
            </div>
        </b-card>



    </div>
</template>

<script>
import {
    BCard, BRow, BCol, BFormSelect, BFormGroup, BButton, BTable, BAvatar, BLink,
    BDropdown, BDropdownItem, BPagination, BTabs, BTab, BListGroup, BListGroupItem, BCardFooter, BCardBody,
    VBTooltip,
    BAvatarGroup,
    BFormCheckbox, BFormInput, 
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useDocumentList from './useDocumentList'
import {avatarText} from '@core/utils/filter'
import {dictToSelectArray} from '@core/utils/utils'
import router from '@/router'
import useAuth from '@/auth/useAuth';
import Ripple from 'vue-ripple-directive'

export default {
    components: {
        BRow,
        BFormSelect,
        BCol,
        BFormGroup,
        BCard,
        BAvatarGroup,
        BButton,
        BTable,
        BAvatar,
        BLink,
        BDropdown,
        BDropdownItem,
        BPagination,
        BTabs,
        BTab,
        BListGroup,
        BListGroupItem,
        BCardFooter,
        BCardBody,
        vSelect,
        BFormCheckbox,
        BFormInput
    },
    directives: {
        Ripple,
        "b-tooltip": VBTooltip
    },
    data() {
        const displayExtentControls = useAuth.getCurrentSoftware() != 'ca' && useAuth.getCurrentSoftware() != 'em'
        return {
            documentTypes: [],
            documentGroups: [],
            assigneeFilter: [],
            statusFilters: [],
            softwareFilter: [],
            pagination: {},
            selectedItems: [],
            currentItems: [],
            selectAllStatus: false,
            displayExtentControls,
            documentFilters: [
                {value: '', text: this.i18nT(`All`)},
                {value: 'created', text: this.i18nT(`Created by me`)},
                {value: 'shared', text: this.i18nT(`Shared by me`)},
            ],
            avalableFields: [
                {label: this.i18nT(`File`), key: 'file', visible: true},
                {label: this.i18nT(`Created by`), key: 'created_by', visible: true},
                {label: this.i18nT(`File size`), key: 'size', visible: true},
                {label: this.i18nT(`Created`), key: 'created', visible: true},
                {label: this.i18nT(`Assigned to`), key: 'assigned_to', visible: true},
            ],
            currentSoftware: useAuth.getCurrentSoftware(),
            controlVisible: true,
        }
    },
    created() {

        let storePreferences = useAuth.getColumnPreferences('documentTemplates');
          if(storePreferences) {
            this.avalableFields = storePreferences
        }


        this.$http.get(
            `documents/documentTypes`
        ).then(({data}) => {
            const types = (data.data).map(type => ({
                value: type.Id,
                text: type.Label
            }))

            types.unshift({
                value: -1,
                text: this.i18nT(`All types`)
            })

            this.documentTypes = types
        })

        this.$http.get(
            `documents/documentGroups`
        ).then(({data}) => {
            const documentGroups = (data.data.document_groups).map(type => ({
                value: type.Id,
                text: type.Label
            }))

            documentGroups.unshift({
                value: -1,
                text: this.i18nT(`All groups`)
            })

            this.documentGroups = documentGroups
        })

        this.$http.get(
            `documents/assignedUsersFilter`
        ).then(({data}) => {
            this.assigneeFilter = dictToSelectArray(data.data)
        })

        this.$http.get(
            `system/softwares`
        ).then(({data}) => {
            const softwareFilter = dictToSelectArray(data.data)

            softwareFilter.unshift({
                value: -1,
                text: this.i18nT(`All`)
            })

            this.softwareFilter = softwareFilter
        })

        this.fetchDocuments(null, this.determineVisibility);

    },
    methods: {
        determineVisibility(documentTempaltesArray) {
          if (documentTempaltesArray.length === 0) {
            this.controlVisible = false;
          }
        },
        getTypeName(typeId) {
            const document = this.documentTypes.find(type => type.value == typeId)
            return document ? document.text : '';
        },
        getAssigneeName(assignee) {
            if (assignee == null) {
                return ""
            }

            return assignee.Label
        },
        getAvatarName(name) {
            if (name) {
                return name
                    .split(' ')
                    .map((namePart) => {
                        return namePart[0]
                    })
                    .join('')
                    .toUpperCase();
            } else {
                return ''
            }
        },
        onTableRefresh() {
            this.selectedItems = []
        },
        onCopy(element) {
            this.$http.post(
                `documents/copyDocument?document_id=${element.item.Id}`
            ).then(() => {
                this.refetchData()
            })
        },
        onDelete(task) {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    this.$http.delete(
                        `documents?id=${task.item.Id}`
                    ).then(() => {
                        this.$swal({
                            icon: 'success',
                            title: this.i18nT(`Deleted!`),
                            text: this.i18nT(`The document has been deleted.`),
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        })
                        this.refetchData()
                    })
                }
            })
        },
        onBulkCopy() {
            this.$http.post(
                `documents/copyDocument?document_id=${this.selectedItems.join(",")}`
            ).then(() => {
                this.selectedItems = []
                this.selectAllStatus = false
                this.refetchData()
            })
        },
        onBulkDelete() {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    this.$http.delete(
                        `documents?id=${this.selectedItems.join(",")}`
                    ).then(() => {
                        this.$swal({
                            icon: 'success',
                            title: this.i18nT(`Deleted!`),
                            text: this.i18nT(`The document has been deleted.`),
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        })
                        this.selectedItems = []
                        this.selectAllStatus = false
                        this.refetchData()
                    })
                }
            })
        },
        onSelectAll(val) {
            if (val) {
                this.selectedItems = this.currentItems.map(item => item.Id)
            } else {
                this.selectedItems = []
            }
        },
        onExportExcel() {

        },
        onExportCsv() {

        }
    },
    setup() {
        const statusOptions = [
            'Downloaded',
            'Draft',
            'Paid',
            'Partial Payment',
            'Past Due',
        ]

        const {
            fetchDocuments,
            tableColumns,
            perPage,
            currentPage,
            totalDocuments,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refDocumentListTable,
            statusFilter,
            documentType,
            documentGroup,
            assignedTo,
            status,
            software,
            documents,
            documentFilter,
            refetchData
        } = useDocumentList(router.currentRoute.meta.template === 1)

        return {
            fetchDocuments,
            tableColumns,
            perPage,
            currentPage,
            totalDocuments,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refDocumentListTable,
            statusFilter,
            statusOptions,
            documentType,
            documentGroup,
            assignedTo,
            status,
            software,
            avatarText,
            documents,
            documentFilter,
            refetchData
        }
    },
    computed: {
        emptyHtml() {
          return "<img src='" + require('@/assets/images/pages/error.svg') + "' alt='Error page' class='img-fluid' width='300'><br/><br/><h4>"
              + this.i18nT(`Here, you can create and manage your document templates. No document templates have been created yet.`) + "</h4>"
              +"<button class='btn btn-outline-secondary mt-2'  onclick='window.location.href=\"/" + this.currentSoftware + "/create-document-template\"'>" + this.i18nT(`Create document template`) + "</button>"
        },
        computedColumns() {

            let col = [...this.tableColumns]

            for(let i = 0; i < this.avalableFields.length; i++) {
              if(!this.avalableFields[i].visible) {
                col = col.filter(c => c.key !== this.avalableFields[i].key)
              }
            }
            useAuth.setColumnPreferences('documentTemplates', this.avalableFields);

            return col
        },
    }
}
</script>

<style lang="scss">
.task-container {
    .task-item {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .line-item {
        margin-top: 4px;
    }
}
</style>
